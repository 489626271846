<template>
  <div>
    <div v-if="!isAutorise">
      <monitor-login />
    </div>
    <div v-else>
      <v-fade-transition mode="in-out" hide-on-leave>
        <monitor-data v-if="!loading" :monitorData="monitorData" :seconds="seconds"></monitor-data>
      </v-fade-transition>
    </div>
    <v-fade-transition mode="in-out" hide-on-leave>
      <div v-if="loading">
        <div class="wrapper">
          <v-sheet color="white" class="h-100 w-100 d-flex align-center justify-center">
            <v-progress-circular indeterminate :size="30" :width="3" color="success " />
          </v-sheet>
        </div>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import metricsService from "@/services/request/metrics/metricsService";
import monitorData from "./monitorData.vue";
import monitorAuth from "@//mixins/monitorAuth";
import localStorageMixin from "@/mixins/localStorage";
import MonitorLogin from "./monitorLogin.vue";
import EventBus from "@/events/EventBus";

const DEFALUT_RELOUD_SEC = 60

export default {
  components: { monitorData, MonitorLogin },
  name: "monitorComponent",
  props: {},
  mixins: [monitorAuth, localStorageMixin, EventBus],
  data: () => ({
    loading: false,
    monitorData: [],
    seconds: 60,
    monitorFetchInterval: null,
    isRunning: false,
  }),
  async mounted() {
    EventBus.$on("on-logout", () => {
      this.setKey(null);
    });
    EventBus.$on("on-login", async inputKey => {
      if (await this.getDataWithLoader(inputKey)) {
        this.setKey(inputKey);
      }
    });

    if (this.getStorageItem("monitorKey")) {
      this.setKey(this.getStorageItem("monitorKey"));
    }

    EventBus.$on("on-reload", () => {
      this.resetTimer();
    });

    if (this.isAutorise) {
      if (!(await this.getDataWithLoader(this.Key))) {
        this.setKey(null);
      }
    }
  },
  destroyed() {
    clearInterval(this.monitorFetchInterval);
  },
  methods: {
    startTimer() {
      this.isRunning = true;
      this.monitorFetchInterval = setInterval(() => {
        if (this.seconds > 0) {
          this.seconds--;
        } else {
          this.resetTimer();
        }
      }, 1000);
    },
    resetTimer() {
      this.isRunning = false;
      clearInterval(this.monitorFetchInterval);
      this.seconds = DEFALUT_RELOUD_SEC;
      this.getData(this.Key);
    },
    async getDataWithLoader(Key) {
      this.loading = true;
      const res = await this.getData(Key);
      this.loading = false;
      return res;
    },
    async getData(key = null) {
      try {
        if (!key) return;
        const formData = new FormData();
        formData.append("key", key);
        const res = await metricsService.getMonitorData(formData);
        this.monitorData = res;
        this.startTimer();
        return !!this.monitorData;
      } catch (e) {
        console.error(e);
      }
    }
  },
  computed: {
    isAutorise() {
      return !!this.Key;
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
