
import { mapActions, mapGetters } from "vuex";
import * as actionTypes from "@/store/modules/monitorKey/types/actions";
import * as getterTypes from "@/store/modules/monitorKey/types/getters";

export default {
  data: () => ({
  }),
  methods: {
    ...mapActions("monitorKey", {
      setKey: actionTypes.SET_KEY
    })
  },
  computed: {
    ...mapGetters("monitorKey", {
      Key: getterTypes.Key
    })
  }
};
