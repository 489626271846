<template>
  <div class="gauge__outer">
    <div class="gauge__inner">
      <div class="gauge__fill" :style="{ transform: `rotate(${cssTransformRotateValue})`, background: fillGradient }"></div>
      <div class="gauge__cover" style="font-size: 4vh">
        {{ percentage.toFixed(0) }}
        <speedometer-icon class="ml-4 mt-2" :size="size/1.5" :fill="getSpeedometerColor" />
      </div>
    </div>
  </div>
</template>

<script>
import SpeedometerIcon from "@/components/icons/Speedometer.vue";

export default {
  components: {SpeedometerIcon},
  props: {
    percentage: {
      type: Number,
      required: true
    },
    size: {
      type: Number,
      default: 32
    },
    speed: {
      type: String
    }
  },
  computed: {
    cssTransformRotateValue() {
      const percentageAsFraction = this.percentage / 100;
      const halfPercentage = percentageAsFraction / 2;

      return `${halfPercentage}turn`;
    },
    fillGradient() {
      if (this.percentage <= 50) {
        const red = Math.round((255 * this.percentage) / 50);
        const green = 255;
        return `linear-gradient(to right, rgb(${red}, ${green}, 0), rgb(255, 255, 0))`;
      } else {
        const red = 255;
        const green = Math.round(255 - ((this.percentage - 50) * 255) / 50);
        return `linear-gradient(to right, rgb(255, 255, 0), rgb(${red}, ${green}, 0))`;
      }
    },
    getSpeedometerColor() {
      const speed = Number(this.speed)
      if (speed < 34) {
        return '#4FAE3B';
      } else if (speed >= 34 && speed <= 68) {
        return '#FBC400';
      } else {
        return '#D74800';
      }
    },
  }
}

</script>

<style scoped>
.gauge__outer {
  width: 100%;
}

.gauge__inner {
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background: #b4c0be;
  position: relative;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  overflow: hidden;
}

.gauge__fill {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: 100%;
  background: var(--gauge-gradient, #00ff00);
  transform-origin: center top;
  transform: rotate(0turn);
  transition: transform 0.2s ease-out, background 0.2s ease-out;
}

.gauge__cover {
  width: 75%;
  height: 150%;
  background: #F5F5F5;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;

  /* Text */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25%;
  box-sizing: border-box;
  font-family: 'Lexend', sans-serif;
  font-weight: bold;
}
</style>
